:root {
  --themeclr: #ACF600;
  --textclr: #CBFD01;
}

a {
  text-decoration: none !important;
}

body {
  background-color: #000 !important;
  /* background-image: url(./Assets/images/bg.png); */
  color: #fff;
  font-family: medium;
  overflow-x: hidden;
}

@font-face {
  font-family: themefont;
  src: url(./Assets/Font/Gilroy-Black.ttf);
}

@font-face {
  font-family: thin;
  src: url(./Assets/Font/Gilroy-Thin.ttf);
}

@font-face {
  font-family: bold;
  src: url(./Assets/Font/Gilroy-Bold.ttf);
}

@font-face {
  font-family: extrabold;
  src: url(./Assets/Font/Gilroy-ExtraBold.ttf);
}

@font-face {
  font-family: medium;
  src: url(./Assets/Font/Gilroy-Medium.ttf);
}

@font-face {
  font-family: normal;
  src: url(./Assets/Font/Gilroy-Regular.ttf);
}

@font-face {
  font-family: light;
  src: url(./Assets/Font/Gilroy-Light.ttf);
}

.container.container-theme {
  max-width: 95%;
}

.normal {
  font-family: normal;
}

.bold {
  font-family: bold;
}

/* .innerheading {
  font-family: extrabold;
} */

.themeclr {
  color: var(--themeclr) !important;
  margin-bottom: 0px;
}

.textclr {
  color: var(--textclr)
}

.flex-1>* {
  flex: 1;
}

.jc-between {
  justify-content: space-between;
}

.br-14 {
  border-radius: 14px;
}

.bgtheme {
  background-color: var(--themeclr);
}

button.ovalbtn {
  background: var(--themeclr);
  border-radius: 30px;
  padding: 4px 10px;
  font-weight: 700;
  border: 1px solid var(--themeclr);
}

.btn.getstarted {
  background: var(--themeclr);
  font-family: 'extrabold';
  border-radius: 0;
  position: relative;
  min-width: 120px;
  padding: 10px 20px;
}

.launchpad .launchbox .btn.getstarted,
.lockedstake .launchbox .btn.getstarted {
  background-color: var(--themeclr);
  border: 1px solid var(--themeclr);
  color: #000;
  border-radius: 6px;

}

.launchpad .launchbox .btn.getstarted:hover,
.lockedstake .launchbox .btn.getstarted:hover {
  background-color: transparent;
  border: 1px solid var(--themeclr);
  color: var(--textclr);
  border-radius: 6px;

}

.launchpad .innerheading,
.lockedstake .innerheading {
  color: #fff;
}

.smallp {
  max-width: 50%;
  margin: auto;
  color: #cbcbcb;
}

button.chocobtn {
  background: var(--themeclr);
  font-size: 16px;
  padding: 5px 15px;
  border-radius: 6px;

  font-weight: 700;
  border: 1px solid var(--themeclr);
}

button.btn.getstarted.dark {
  background: #000;
  color: #fff;
}

/* button.btn.getstarted:after {
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid white;
  position: absolute;
  left: -5px;
  bottom: 4px;
} */

section {
  margin-top: 65px;
  margin-bottom: 65px;
}

.land section.banner {
  margin-top: 0px;
  margin-bottom: 0px;
  height: 100vh;
}

.land section.innersec {
  padding-top: 50px;
  padding-bottom: 50px;
}

/* button.btn.getstarted:after {
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid white;
  position: absolute;
  left: -5px;
  bottom: 4px;
  transition: 0.2s;
} */

.ban-cont p,
.features-list p {
  font-family: normal;
  color: #fff;
  font-size: 23px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: center;
  width: 50%;
  margin: auto;
  margin-bottom: 20px;

}

.btn.btn-theme {
  background-color: var(--textclr);
}

.splitsec .cont_sec {
  width: 90%;
  margin: auto;
}

.splitsec .cont_sec .main_row {
  align-items: center;
}

.splitsec .cont_sec .content_sec .themeclr {
  margin-bottom: 10px;
}

.splitsec .cont_sec .content_sec .desc {
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: justified;

}

.splitsec .cont_sec .imgsec {
  position: relative;
  text-align: center;
}

.splitsec .cont_sec .imgsec .gradimg {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 20%;
  top: -15%;
  z-index: -1;
}

/* .splitsec .imgsec .sec_img{
  width: 350px;
  height: 350px;
} */

.splitsec .cont_sec.cl_reverse .imgsec .gradimg {
  right: unset;
  left: 10%;
  z-index: -1;
}

.roadmap .innerheading {
  font-size: 45px;
  font-weight: 700;
  line-height: 59px;
  letter-spacing: 0em;
  text-align: center;

}

.roadmap .ban-cont p {
  font-size: 18px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: center;

}

.dashsec {
  background: url('./Assets/images/testgrad.png');
  background-size: 100% 100%;
}

.dashsec .cont_sec {
  width: 90%;
  margin: auto;
}

.dashsec .cont_sec .main_row {
  text-align: center;
  width: 60%;
  margin: auto;
}

.dashsec .cont_sec .main_row .desc {
  line-height: 30px;
}

.easy_earnsec {
  background: url('./Assets/images/testgrad.png');
  background-size: 100% 100%;
}

.easy_earnsec .img_row {
  background: url('./Assets/images/testline.png');
  background-repeat: no-repeat;
  background-position: center;

}

.easy_earnsec .img_row .stepimg {
  width: 335px;
  height: 350px;
}

.easy_earnsec .img_row .head {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;

}

.easy_earnsec .img_row .minihead {
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;

}

.easy_earnsec .img_row .colsec {
  text-align: center;
}

.testmony_sec .test_card {
  background: #010101;
  border: 1px solid #CBFD01;
  height: 100%;
  padding: 20px;
  border-radius: 12px;

}

.testmony_sec {
  background: url('./Assets/images/testgrad.png');
  background-size: 100% 100%;
  display: flex;
}

.testmony_sec .owl-item {
  opacity: 0.2;
}

.testmony_sec .owl-item .test_card {
  background: #cbfd017a;
}

.testmony_sec .owl-item.center .test_card {
  background: #010101;
}

.testmony_sec .owl-item.center {
  opacity: 1;
}

.testmony_sec .test_card .name {
  font-size: 22px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--textclr);
  margin-bottom: 5px;
}

.testmony_sec .test_card .cname {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--textclr);
  margin-bottom: 5px;
}

.testmony_sec .test_card .desc {
  font-size: 14px;
  font-weight: 300;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;

  color: var(--textclr);
}

.testmony_sec .owl-nav {
  display: block !important;

}

.testmony_sec .owl-nav button {
  background: var(--themeclr) !important;
  border-radius: 4px;
  width: 30px;
  height: 30px;

}

.testmony_sec .owl-nav button span {
  color: #000;


}

.blogsec .main_row {
  align-items: center;
}

.blogsec .main_row {
  width: 90%;
  margin: auto;
}

.blogsec .blogrow {
  width: 80%;
  margin: auto;
}

.blogsec .blogrow .maintrendimg {
  width: 100%;
  border-radius: 5px;
  height: 400px;
  object-fit: cover;
}

.blogsec .blogrow .rightsec .catname {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 10px;
  color: var(--themeclr);
}

.blogsec .blogrow .rightsec .titlename {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
}

.blogsec .blogrow .rightsec .subcard {
  margin-bottom: 20px;
}

.blogsec .blogrow .rightsec .subcard .subtrendimg {
  width: 100%;
  height: 220px;
  border-radius: 5px;
  object-fit: cover;
}

.blogsec .blogrow .leftsec .trendcard {
  background: #010101;
  border: 1px solid var(--themeclr);
  border-radius: 8px;
  padding: 25px;
  width: 95%;
  margin-top: -20px;
  position: relative;
  z-index: 9;
}

.blogsec .blogrow .leftsec .trendcard .catname {
  color: var(--textclr);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-align: left;
}

.blogsec .blogrow .leftsec .trendcard .title {
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  text-decoration: underline;

}

.blogsec .blogrow .leftsec .trendcard .desc {

  font-size: 14px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;


}

/* header */
.land .navbar-nav .getstarted {
  display: none;
}

.header .navbar-brand .logo {
  width: 100%;
  height: 30px;
}

.header .bg-dark {
  background: transparent !important;
  box-shadow: none !important;

  width: 100%;
  z-index: 999;
}
.header.fxheader .bg-dark{
  box-shadow: 0px 4px 4px 0px #FFFFFF40 !important;
  background:  #000000 !important;
  position: fixed;

}
.launchpad .header.fxheader .bg-dark,.lockedstake .header.fxheader .bg-dark{
  margin-top: -25px;
}

.header .navbar-nav a {
  border-bottom: 3px solid transparent;
  margin-left: 20px;
  margin-right: 20px;
  color: #fff !important;
}

.header .navbar-nav a:hover,
.header .navbar-nav a.nav-link.active {
  /* border-bottom: 3px solid var(--textclr); */
  color: var(--themeclr) !important;
}

.header .navbar-nav a:last-child {
  margin-right: 0;
}

.header .navbar-nav a:first-child {
  margin-left: 0;
}

.lockedstake nav.navbar .btn.getstarted,
.launchpad nav.navbar .btn.getstarted {
  max-width: 150px;
}

.btn-overall {
  background: var(--themeclr);
  color: #000;
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  border: 1px solid var(--themeclr);
  padding: 8px 10px;
  border-radius: 6px;

}

.btn-overall:hover {
  background: transparent;
  color: var(--textclr);
  border: 1px solid var(--themeclr);

}

/* tabs */
ul.nav-tabs {
  max-width: 435px;
  flex-flow: nowrap;
  overflow-x: auto;
  padding-bottom: 15px;
}

ul.nav-tabs li {
  flex: 1;
  white-space: nowrap;
}

li.nav-item button {
  background: transparent !important;
  border-color: transparent !important;
  color: #ffffff8c;
  width: 100%;
}

li.nav-item button:hover {
  color: #fff !important;
}

li.nav-item button.active {
  color: #000 !important;
  background-color: var(--themeclr) !important;
  border-radius: 5px;
}

ul.nav-tabs {
  border: 0 !important;
}


.news {
  position: relative;
}

.news .newstoprow {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
}

.news .newstoprow .innerdesc {
  width: 70%;
  margin: auto;
}

.news .topcarousel .cardstyle {
  border: 1px solid var(--themeclr);
  border-radius: 5px;
  width: 100%;
  height: 500px;

}
.newshead{
  padding-left: 50px;
}
.news .topcarousel .innercard {
  background: #020202c9;
  border: 1px solid var(--themeclr);
  padding: 30px;
  width: 60%;
  height: 50%;
  position: absolute;
  top: 8%;
  left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
}

.news .topcarousel .cardstyle .cardimg {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.news .newstoprow .banner_grad {
  position: absolute;
  z-index: -1;
  left: 10%;
}

/* .newshead{margin-top:-24px;} */
.news .items {
  position: relative;
  /* height: 90vh; */
}

.news .itemsfles {
  display: flex;
  align-items: start;
  flex-direction: column;
  max-height: 100vh;
  justify-content: center;
  width: 50%;
  height: 100%;
  min-height: 80vh;
  position: absolute;
  bottom: 0;
  left: 41px;
  right: 0;
  top: 0;
}

.news .itemsfles h2 {
  font-size: 50px;
  font-weight: 600;
}

.news .itemsfles p {
  font-size: 16px;
  font-weight: 400;
}

.news .owl-carousel .owl-nav {
  position: absolute;
  bottom: 50px;
  right: 80px;
}

.news .owl-carousel .owl-nav button.owl-next {
  background-color: var(--themeclr) !important;
  color: #000 !important;
  font-size: 20px;

  border-radius: 8px;
  /* width: 42px; */
}

.news .owl-carousel .owl-nav button.owl-prev {
  background-color: var(--themeclr) !important;
  color: #000 !important;
  font-size: 20px;

  border-radius: 8px;
  /* width: 42px; */
}

.news .owl-carousel .owl-nav button.owl-prev:hover,
.news .owl-carousel .owl-nav button.owl-next:hover {
  background-color: #fff;
}

.Editor_pick {
  cursor: pointer;
}

.Editor_pick h2 {
  color: #fff;
  /* font-family: extrabold; */
  padding: 30px 0;
}

.col_token:nth-child(even) .token_list {
  background-color: #DDDDDD;
  border: 1px solid #DDDDDD;
  border-radius: 5px;
  margin-top: 20px;
  transition: all 0.5s ease;
  margin-bottom: 6px;
}

.token_list {
  background: linear-gradient(180deg, #1E1E1E 0%, #000000 100%);

  border: 1px solid #DDDDDD;
  border-radius: 8px;
  margin-top: 20px;
  transition: all 0.5s ease;
  margin-bottom: 6px;
  padding: 5px;
}

.token_list .sec_border {
  border: 1px solid #DDDDDD;
  border-radius: 8px;
  height: 100%;
}

.tokenimg {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 250px;
  max-height: 250px;
  overflow: hidden;
}

.tokenimg img {
  min-height: 250px;
  object-fit: cover;
  max-width: 100%;
  padding: 6px;
  border-radius: 5px;
}

.token_items h2 {
  color: #fff;
  /* font-family: extrabold; */
  padding: 8px;
  margin-bottom: 0;
  font-size: 18px;
}

.token_items p {
  color: #fff;
  padding: 8px;
  font-size: 14px;
  margin-bottom: 0;
}

.launchpad .token_list {
  background-color: #DEC508;
  border: 1px solid #DEC508;
  border-radius: 5px;
  margin-top: 20px;
  transition: all 0.5s ease;
  margin-bottom: 6px;
  margin-right: 8px;
}

.launchpad .col_token .token_list {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  margin-top: 20px;
  transition: all 0.5s ease;
  margin-bottom: 6px;
  margin-right: 8px;
}

.launchpad .tokenimg {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 250px;
  max-height: 250px;
  overflow: hidden;
  padding: 0;
}

.launchpad .tokenimg img {
  min-height: 250px;
  object-fit: cover;
  max-width: 100%;
}

.launchpad .bannerbox {
  position: relative;
}

.launchpad .bannerbox .banner_grad {
  position: absolute;
  left: -10%;
  right: 0px;
  top: 2%;

}

.lockedstake .bannerbox {
  position: relative;
}

.lockedstake .bannerbox .banner_grad {
  position: absolute;
  left: -10%;
  right: 0px;
  z-index: -1;

}

.newdetail {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  cursor: pointer;
}

.newdetail img {
  min-height: 100vh;
  max-width: 100%;
  object-fit: cover;
  min-width: 100%;
}

.newdetailsec {
  background-color: #151515;
  margin-top: -100px;
}

.recent_post {
  background-color: #151515;
  margin-top: 50px;
  padding: 20px;
}

.no-bord {
  border: 0 !important;
}

.vdieo_hide {
  border: 1px solid #eee;
  padding: 15px 15px 15px 15px;
  text-align: center;
}

.form_loist {
  background-color: #fff;
  margin-top: 50px;
  padding: 20px;
}

.newdetailsec p span {
  color: #fff !important;
}

.newdetailsec h2 {
  color: #fff;
  font-size: 40px;
  padding: 30px 30px 10px 30px;
}

.newdetailsec h2 span {
  color: #fff !important;
}

.recent_post h2 {
  color: var(--textclr);
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 10px;
}

.form_loist h2 {
  color: #000;
  font-size: 30px;
  font-weight: 600;
  padding-bottom: 10px;
}

.imgdive {
  display: flex;
  padding: 0 5px 40px 5px;
  font-size: 0;
  flex-flow: row wrap;
  justify-content: center;
  gap: 1px;
  max-width: 800px;
  margin: 0 auto;
}

.imgs {
  margin: 0;
  min-width: 201px;
  flex: 0.67;
  transition: .5s;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-height: 250px;
  max-height: 250px;
}

.imgs img {
  max-width: 100%;
  min-height: 250px;
  object-fit: cover;
}

.recentblogdet {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 20px;
}

.recent_post a {
  color: #fff !important;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  margin-left: 20px;
  transition: all 0.5s ease;
}

.news .sec_head {
  text-align: center;
}

.recent_post a:hover {
  color: var(--textclr) !important;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  margin-left: 20px;
}

.recent_post img {
  width: 80px;
  height: 70px;
  object-fit: cover;
}

.dayscmment {
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 30px;
  cursor: pointer;
}

.dayscmment p {
  color: #fff !important;
  font-size: 14px;
  margin-right: 10px;
}

.newdetailsec .textconete {
  color: #fff !important;
  font-size: 14px;
  margin-right: 10px;
  padding: 0px 30px 30px 30px;
}

.dayscmment i {
  color: #818181;
  font-size: 14px;
}

/* banner */

.heading {
  font-size: 55px;
}

.banner {
  height: 100vh;
  margin-top: 0px;
  margin-bottom: 0px;
}

.banner .heading {
  font-size: 120px;
  font-weight: 700;
}

.bannerimg img {
  width: 100%;
  max-width: 500px;
}

.banner .banner_row {
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.banner .banner_row .ban-cont {
  text-align: center;
  position: relative;
}

.banner .banner_row .ban-cont .banner_grad {
  position: absolute;
  left: -5%;
  bottom: -65%;
  z-index: -1;
}

.land .sec_img {
  height: 450px;
}

.banner .lear_more_sec {
  color: var(--textclr);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.banner .lear_more_sec img {
  width: 30px;
  height: 30px;
}

/* feature */
.features-list {
  border: 1px solid transparent;
  border-radius: 14px;
}

.features-list:hover {
  border: 1px solid var(--themeclr);
}

/* roadmap */
h6.roadmapbtn:after {
  position: absolute;
  content: "";
  height: 55px;
  width: 1px;
  background: #C7C7C7;
  top: -50px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
}

h6.roadmapbtn {
  position: relative;
}

.line:after {
  content: "";
  height: 20px;
  width: 20px;
  background: var(--themeclr);
  display: block;
  color: var(--themeclr);
  position: absolute;
  border-radius: 50%;
  border: 2px solid #DADFE9;
  left: 0;
  right: 0;
  margin: auto;
  top: 14%;
  z-index: 1;
}

.car-item:after {
  width: 100%;
  content: "";
  height: 6px;
  background: var(--themeclr);
  position: absolute;
  left: 0;
  right: 0;
  /* top: 26%; */
  top: 15%;
}

.fullinerelative {
  position: relative;
}

.fullline {
  position: absolute;
  height: 6px;
  width: 100%;
  background: #2D2D2D;
  top: 75px;
  border-radius: 10px;
}

.roadmap .owl-item:first-child .car-item:after {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.logogrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 50px;
  place-items: center;
}

.logogrid img {
  width: 10vw;
  max-height: 3vw;
}

.notyetstart .line:after {
  background: var(--themeclr);
  border-color: #817E7E;
  border-width: 3px;
}

.owl-carousel .owl-nav button.owl-next:hover,
.owl-carousel .owl-nav button.owl-prev:hover {
  background: transparent;
}

.notyetstart:after {
  background: transparent;
}

.inprogress:after {
  width: 50%;
}

.car-item h6:first-child {
  margin-bottom: 66px;
}

.car-item h6:first-child {
  margin-bottom: 66px;
}

.car-item {
  text-align: center;
  position: relative;
  width: 100%;
}

.car-item p {
  color: #fff;
  font-size: 15px;
  padding-right: 15px;
  /* min-height: 88px; */
  min-height: 330px;
  line-height: 30px;
  font-weight: 400;
}

.infos p,
.marketcapital p {
  font-size: 18px;
}

.roadmap .owl-nav button span {
  margin-top: 0 !important;
}

.car-item .line+h6 {
  margin-top: 80px;
  min-height: 120px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.car-item h6 {
  color: white;
}

h6.roadmapbtn {
  background: var(--themeclr);
  max-width: 114px;
  padding: 7px;
  border-radius: 20px;
  color: #000;
  margin: auto;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 11px;
}

.slider_sec_1 img {
  max-width: 170px;
  margin: 20px auto;
  transition: all 0.5s ease;
  cursor: pointer;
  /* max-height: 110px;
  min-height: 110px; */
}

.slider_sec_1 .owl-carousel .owl-nav .owl-prev span,
.slider_sec_1 .owl-carousel .owl-nav .owl-next span {
  /* display: none; */
  color: #97afd5;
}

.slider_sec_1 .owl-carousel .owl-nav .owl-prev span,
.slider_sec_1 .owl-carousel .owl-nav .owl-next span {
  color: #97afd5;
  border: 1px solid #97afd5;
  height: 35px;
  width: 35px;
  display: inline-block;
  border-radius: 50%;
  font-size: 35px;
  font-weight: 100;
  line-height: 0.8;
  margin-top: 40px;
}

.slider_sec_1 .owl-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 150px !important;
}

.slider_sec_1 .owl-item div,
.slider_sec_1 .owl-item div a {
  height: 100%;

}

.slider_sec_1 .owl-item .car-item>div {
  padding-top: 40px;
}



/* launchpad */
.bannerbox .innerheading {
  font-size: 45px;
  /* text-shadow: 0px 8px 2px #5f59592b; */
}

.bordbox {
  border: 1px solid var(--themeclr);
}

.launchbox {
  background-color: #040404;
  border: 1px solid var(--themeclr);
  position: relative;
}

.launchpad button.chocobtn {
  position: absolute;
  right: 24px;
  top: -17px;
}

.launchpad .innerdesc {
  font-size: 16px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;

}

.lockedstake .innerdesc {
  font-size: 16px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;


}

.progress {
  background-color: #272727;
  height: 10px;
}

.progress-bar {
  background: var(--textclr);
  border-radius: 5px;
}

.launchbox h6 {
  font-family: 'bold';
}

.logocont img {
  border: 2px solid var(--themeclr);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  padding: 5px;
  object-fit: contain;
}

.logocont span {
  text-transform: uppercase;
  font-family: normal;
}

.launchpad .bgtheme .innerheading {
  text-shadow: 0px 8px 2px #5f59592b
}

.calcicon {
  width: 12px;
}


/* modal */
.modal-backdrop.show {
  opacity: .9;
}

.modal-content {
  background: #0F0F0F;
}

.modal-backdrop {
  background-color: #2E2E28;
}

.yellowbox {
  border: 1px solid var(--themeclr);
  background: #000000;
  border-radius: 15px;
}

.bannerbox {
  min-height: 181px;
}

.modal .yellowbox.connect.text-center {
  max-width: 185px;
  margin: auto;
}

.modal .yellowbox img {
  max-width: 50px;
  height: auto;
}

.modal-sm {
  max-width: 400px;
  margin: auto;
}

.btn-link.close:hover {
  color: var(--themeclr);
}

.input-group input {
  background: #000 !important;
  border: none;
  border-radius: 8px;
  color: #fff;
  min-height: 48px;

}

.input-group.h-75 input {
  height: 75px;
}

span.input-group-text {
  background: #000;
  border: none;
  border-radius: 8px;
}

.input-group {
  border: 1px solid var(--themeclr);
  border-radius: 8px;
}

.input-group input:focus {
  box-shadow: none;
}

.form_loist label {
  color: #5e5e5e;
  font-size: 12px;
}

.labelforme {
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.form_loist .labelforme textarea {
  --webkit-appearance: none !important;
  appearance: none !important;
  border: 1px solid #5e5e5e33 !important;
  margin-top: 10px;
  width: 98%;
}

.form_loist .labelforme textarea:focus {
  outline: none;
}

.form_loist .labelforme input[type="text"] {
  --webkit-appearance: none !important;
  appearance: none !important;
  border: 1px solid #5e5e5e33 !important;
  max-width: 95%;
  min-width: 80%;
  height: 40px;
  width: 100%;
}

.labelformecheck {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.submitfomer {
  background-color: #ACF600;
  font-size: 14px;
  color: #000;
  text-decoration: none;
  border-radius: 5px;
  font-weight: 500;
  padding: 12px 14px !important;
  height: 50px;
  margin-top: 30px;
  border: 1px solid #ACF600;
  transition: all 0.5s ease;
}

.vdieo_hide iframe {
  max-width: 100%;
  width: 550px;
  max-height: 300px;
  height: 300px;
  min-height: 300px;
}

.submitfomer:hover {
  background-color: transparent;
  color: #000;
}

.labelformecheck p {
  color: #5e5e5e;
  font-size: 14px;
  margin-bottom: 0;
  margin-left: 10px;
}

.form_loist .labelforme input:focus {
  outline: none;
}

.inputfoe {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

/* footer */
.footer {
  border-top: 1px solid var(--themeclr);
  padding-top: 25px;
  padding-bottom: 10px;
  margin-top: 54px;
}

.footer .textclr {
  font-family: light;
}

.footer ul li {
  list-style-type: none;
  display: inline-block;
}

.footer ul li {
  margin-left: 10px;
  margin-right: 10px;
}

.footer ul li:first-child {
  margin-left: 0;
}

.footer ul li span {
  color: var(--textclr);
  font-size: 25px;
}

.bannerwal_ul {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap:30px 40px ;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: auto;
  padding-left: 0px;
}
.bannerwal_ul  img{
  width: 100%;
  height: 40px;
  object-fit: contain;
}
.land .banner .rollerdownsec{
  position: absolute;
  right: 5%;
  bottom: 20%;
  background: url('./Assets/images/roller.gif');
  background-size: 100% 100%;
  height:100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.land .banner .rollerdownsec .rollerimg{
  width: 100px;
  height: 100px;
}
.land .banner .rollerdownsec .imgrollsec{
  position: relative;
}
.land .banner .rollerdownsec .imgrollsec{
  position: relative;
}
.land .roadmap{
  position: relative;
}
.land .roadmap .rollerdownsec{
  position: absolute;
  right: 0%;
  bottom:-5%;
  background: url('./Assets/images/roller.gif');
  background-size: 100% 100%;
  height:100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.land .roadmap .rollerdownsec .rollerimg{
  width: 100px;
  height: 100px;
}
.land .roadmap .rollerdownsec .imgrollsec{
  position: relative;
}
.land .roadmap .rollerdownsec .imgrollsec{
  position: relative;
}

@media(min-width:1601px) {

  .car-item p {
    min-height: 78px;
  }

  .line:after {
    top: 14%;
  }

}

@media(max-width:1199px) {
  .smallp {
    max-width: 100%;
  }

  .news .itemsfles {
    min-height: 60vh;
  }

  .news .itemsfles h2 {
    font-size: 40px !important;
  }

  .news .items {
    height: 60vh;
  }

  .news .owl-carousel .owl-nav {
    bottom: 50px;
  }
}

@media(max-width:992px) {
  .news .itemsfles h2 {
    font-size: 40px;
  }

  .news .itemsfles {
    min-height: 50vh;
  }
}

@media(max-width:991px) {
  .navbar-collapse {
    position: absolute;
    background: black;
    width: 100%;
    top: 58px;
    left: 0px;
    padding: 40px;
  }

  .news .items {
    height: 90vh;
    background-size: 100%;
  }

  .news .itemsfles {

    width: 100%;
  }

  .news .itemsfles p {
    width: 80%;
  }

  .news .itemsfles h2 {
    font-size: 36px;
  }

  .navbar-collapse a {
    max-width: max-content;
    max-width: fit-content;
  }

  .bannerbox .innerheading {
    font-size: 30px;
  }

  .header .navbar-nav a {
    margin: 0;
  }

  .heading {
    font-size: 40px;
  }

  .bannerimg img {
    max-width: 390px;
  }
}

@media(max-width:767px) {
  .land .banner .rollerdownsec,.land .roadmap .rollerdownsec {
    display: none;
  }
  .land section.banner{
    padding-top: 50px;
  }
  .infos p,
  .marketcapital p {
    font-size: 16px;
  }

  .news .owl-carousel .owl-nav {
    bottom: 0px;
  }

  .news .itemsfles {
    min-height: 41vh;
  }

  .news .items {
    height: 90vh;
    background-size: 100% 100%;
  }

  .navbar-brand img {
    height: 40px;
  }

  .banner .heading {
    font-size: 50px;
  }

  .ban-cont p,
  .features-list p {
    width: 100%;
    line-height: 30px;
    font-size: 16px;
  }

  .land .sec_img {
    height: 100%;
  }

  .land section.innersec {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .dashsec .cont_sec .main_row {
    width: 100%;
  }

  .splitsec .cont_sec .content_sec .themeclr {
    text-align: center;
  }

  .splitsec .heading {
    text-align: center;
    font-size: 30px;
  }

  .splitsec .cont_sec .content_sec .desc {
    text-align: center;
    line-height: 30px;
  }

  .btnsec_started {
    text-align: center;
  }

  .easy_earnsec .img_row .stepimg {
    height: auto;
    width: 100%;
  }

  .easy_earnsec .img_row {
    background: none;
  }

  .col_swap {
    flex-direction: column-reverse;
  }

  .blogsec .themeclr,
  .blogsec .heading,
  .blogsec .desc {
    text-align: center;
  }

  .blogsec .blogrow {
    width: 100%;
  }

  .blogsec .blogrow .maintrendimg {
    height: 320px;
  }

  .blogsec .blogrow .leftsec .trendcard {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
  }

  .blogsec .blogrow .leftsec .trendcard .title {
    font-size: 20px;
    font-weight: 700;
  }

  .blogsec .blogrow .leftsec .trendcard .desc {
    font-size: 14px;
    font-weight: 700;
    line-height: 25px;
  }
  .newshead{
    width: 90%;
    margin: auto;
    padding-left: 0px;
  }
  .news .newstoprow .banner_grad {
    left: -3%;
  }
  .news .topcarousel .innercard {
    width: 90%;
    height: 75%;
    top: 4%;
  }
}

.logos_sec {
  max-width: 80%;
  margin: 0 auto;
}

@media(max-width:575px) {
  .newdetailsec {
    margin-top: -60px;
  }

  .logogrid {
    grid-template-columns: repeat(3, 1fr);
  }
  .bannerwal_ul{
justify-content: center;
  }

}

@media(max-width:425px) {
  .slider_sec_1 img {
    max-width: 99px;
    margin: 0px auto;
  }

  footer {
    text-align: center !important;
    margin: 0 auto !important;
  }

  .newdetail {
    min-height: unset;
    max-height: unset;
  }

  .newdetail img {
    min-height: unset;
  }

  .items .newdetail img {
    min-height: 100vh;
  }

  .news .itemsfles p {
    font-size: 14px;
  }

  .newdetailsec h2 {
    font-size: 30px;
  }

  .connect p {
    font-size: 12px;
  }

  .news .itemsfles h2 {
    font-size: 24px !important;
  }

  .news .owl-carousel .owl-nav button.owl-next,
  .news .owl-carousel .owl-nav button.owl-prev {
    font-size: 22px;
    width: 32px;
  }

  .news .owl-carousel .owl-nav {
    left: 0;
    right: 0;
  }
}

@media screen and (min-width:768px) and (max-width:991px) {
  .car-item p
  {
    min-height: 400px;
  }
  .banner .heading {
    font-size: 60px;
  }
  .land .banner .rollerdownsec {
    bottom: 30%;
  }
  .ban-cont p,
  .features-list p {
    font-size: 16px;
    font-weight: 700;
    line-height: 30px;

  }

  .splitsec .heading {
    font-size: 30px;
  }

  .splitsec .cont_sec .content_sec .desc {
    font-size: 14px;
    line-height: 30px;
  }

  .land .sec_img {
    height: auto;
  }

  .easy_earnsec .img_row {
    background: none;
  }

  .blogsec .blogrow {
    width: 90%;
  }

  .blogsec .themeclr,
  .blogsec .heading,
  .blogsec .desc {
    text-align: center;
  }

  .blogsec .blogrow .leftsec .trendcard {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
  }
}

@media only screen and (min-width:1601px)
{
  .car-item .line + h6
  {
    min-height: 150px;
  }
  .car-item p
  {
    min-height: 240px;
  }
}

@media screen and (min-width:992px) and (max-width:1199px) {
  .banner .heading {
    font-size: 60px;

  }

  .easy_earnsec .img_row .stepimg {
    height: auto;
  }

  .easy_earnsec .heading {
    font-size: 40px;
  }

  .testmony_sec .owl-item.center .test_card {
    background: #010101;
    width: 90%;
    margin: auto;
  }

  .ban-cont p,
  .features-list p {
    font-size: 16px;
    font-weight: 700;
    line-height: 30px;

  }

  .splitsec .heading {
    font-size: 30px;
  }

  .splitsec .cont_sec .content_sec .desc {
    font-size: 14px;
    line-height: 30px;
  }

  .land .sec_img {
    height: auto;
  }

  .easy_earnsec .img_row {
    background: none;
  }

  .blogsec .blogrow {
    width: 90%;
  }

  .blogsec .blogrow .leftsec .trendcard {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
  }
}

@media(min-width:1800px) {
  .heading {
    font-size: 75px;
  }
}

.news .Editor_pick.firstsec .token_list {
  height: 100%;
}

.newdetailsec h2 {
  font-weight: 900;
}

.newdetailsec .textconete b {
  font-size: 16px;
  color: var(--textclr);
}

.launchpad .noticelink {
  color: #838383 !important;
  font-size: 18px;

}

.launchpad .menu_list {
  position: relative;

}

.launchpad .notice_link {
  position: absolute;
  right: 0px;

}

ul.nav-tabs.tab_size_small li {
  max-width: 100px !important;
}


/* .Editor_pick.firstsec .col_token{
  height: 100%;

} */
.land,
.launchpad {
  background: #00000047;
}

@media(min-width:1200px) {
  .lockedstake .innerdesc {
    margin: auto;
    width: 70%;
  }
}
