@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
  margin: 0;
  font-family: 'Chakra Petch', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url('./Assets/images/bg_gif1.gif');
  background-repeat: repeat;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn.getstarted.small {
  min-width: 50px;
}

.nounder {
  text-decoration: none;
}

.input-group-text .dropdown-item:focus {
  background-color: var(--themeclr);
}

#loader_div {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #000000c7;
  z-index: 99999;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loader img {
  animation: loader;
  animation-iteration-count: infinite;
  animation-duration: 1s;
}

@keyframes loader {
  0% {
    transform: rotateY(360deg);
  }

  100% {
    transform: rotateY(0deg);
  }
}

.transform-component-module_wrapper__1_Fgj {
  height: unset !important;
  width: unset !important;
  overflow: auto;
}

.maptop_row .titlerow {
  width: 75%;
  margin: auto;
  text-align: center;
  margin-top: 20px;
}

.maptop_row .titlerow .title {
  font-size: 40px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;

}

.maptop_row .titlerow .desc {
  font-size: 16px;
  font-weight: 500;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  width: 50%;
  margin: auto;

}
.maptop_row {
  margin-bottom: 30px;
}
.maptop_row .mapborderbox {
  border: 2px solid #ffffffc6;
  border-style: dashed;
  width: 50%;
  margin: auto;
}
.mapborderbox{
  padding: 10px;
}
.mapborderbox .boxtitle {
  font-size: 18px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;

}
.mapborderbox .d-flex {
  gap: 7px;
}

.mapborderbox p span {
  height: 10px;
  width: 10px;
  display: inline-block;
  border-radius: 2px;
  margin-left: 14px;
  margin-right: 2px;
}

.mapborderbox p span.avail {
  background-color: var(--themeclr);
}

.mapborderbox p span.wait {
  background-color: #269CAA;
}

.mapborderbox p span.soon {
  background-color: #D6187B;
}
.mapborderbox .boxcolor{
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
}

/* .maptop_row {
  width: 70%;
  margin: auto;
} */
.colors {
  padding: 15px;
  background: #6c666691;
  max-width: 450px;
  position: absolute;
  top: 14px;
  left: 50px;
  z-index: 9;
}

.colors p {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.colors .d-flex {
  gap: 7px;
}

.colors p span {
  height: 10px;
  width: 10px;
  display: inline-block;
  border-radius: 2px;
  margin-left: 14px;
  margin-right: 2px;
}

.colors p span.avail {
  background-color: #ACF600;
}

.colors p span.wait {
  background-color: #269CAA;
}

.colors p span.soon {
  background-color: #D6187B;
}

.noshrink {
  flex-shrink: 0;
}

@media(max-width:575px) {
  .colors .d-flex {
    gap: 2px;
  }

  .colors {
    max-width: 372px;
    padding: 12px;
    left: 12px;


  }

  .colors p {
    font-size: 11px;
  }
}

@media(max-width:575px) {
  .colors {
    left: 5px;
    right: 5px;
  }
 
}

.latestarticle .token_items {
  min-height: 132px;
}

.latestarticle .token_items h2 {
  color: #fff;
  /* font-family: extrabold; */
  padding: 8px;
  margin-bottom: 0;
  font-size: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 388px;
  white-space: nowrap;
}

.latestarticle .owl-nav {
  position: unset !important;
  width: 100%;
  top: 0;
  right: 0 !important;
  bottom: 0 !important;
  margin: auto;
  height: 100%;
  display: flex;
  justify-content: center ;
  align-items: center;
}

.latestarticle .owl-nav .owl-prev {
  /* position: absolute; */
  left: -6%;
  z-index: 2;
  top: 40%;
  background: #55464600 !important;
  color: #fff !important;
}

.latestarticle .owl-nav .owl-next {
  /* position: absolute; */
  right: -6%;
  z-index: 2;
  top: 40%;
  background:var(--themeclr) !important;
  color: #fff !important;
  border-radius: 0px;

}
.latestarticle .Editor_pick.launchpad .owl-nav button {
border-radius: 5px;
}
.carouselcontainer {
  max-width: 90%;
}

.latestarticle .owl-nav .owl-prev span,
.latestarticle .owl-nav .owl-next span {
  font-size: 24px;
  padding: 15px;
}

.latestarticle a.col_token {
  height: 100%;
  display: block;
}

.latestarticle .token_list {
  height: 100%;
}

.latestarticle .owl-item {
  z-index: 1;
}

.latestarticle .owl-stage {
  /* display: flex; */
}

.carouselcontainer .token_list {
  margin-right: 0 !important;
}

.mapimg {
  background-image: url("./Assets/images/world.svg");
  width: 100vw;
  height: 100vh;
  background-repeat: repeat;
}

@media(max-width:767px) {

  .latestarticle .owl-nav .owl-prev,
  .latestarticle .owl-nav .owl-next {
    position: unset;
  }

  .latestarticle .owl-nav {
    justify-content: center;
  }
  .maptop_row .titlerow {

    width: 100%;
  }
  .maptop_row .titlerow .desc{
    margin-bottom: 20px;
    width: 80%;
  }
  .mapborderbox .boxcolor{
    justify-content: start;
  }
  .maptop_row .titlerow .title {
    font-size: 30px;
  }
}
.modal-header{
  border-color: #ACF600 !important;
}
.form_text_white
{
  color:#fff !important;
}